import React, { useEffect, useState } from 'react';
import './Home.css';
import paper1 from '../../Assets/1.png';
import paper2 from '../../Assets/2.png';
import paper3 from '../../Assets/3.png';
import $ from 'jquery';

const Home = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            // Increment the image index
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 3);
        }, 2000);

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const preloadImages = async () => {
            try {
                await Promise.all([paper1, paper2, paper3].map((image) => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = image;
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                }));
                setImagesLoaded(true);
            } catch (error) {
                console.error('Error preloading images:', error);
            }
        };

        preloadImages();
    }, []);

    useEffect(() => {
        if (imagesLoaded) {
            $('.paper1').fadeIn(2000);
            $('.paper2').fadeIn(2000);
            $('.paper3').fadeIn(2000);
        }
    }, [imagesLoaded]);

    return (
        <div className='Home'>
            {imagesLoaded && (
                <>
                    <img
                        src={currentImageIndex === 0 ? paper1 : currentImageIndex === 1 ? paper2 : paper3}
                        className={`paper1 ${currentImageIndex === 0 ? 'active' : ''}`}
                        style={{ display: 'none' }}
                    />
                    <img
                        src={currentImageIndex === 1 ? paper1 : currentImageIndex === 2 ? paper2 : paper3}
                        className={`paper2 ${currentImageIndex === 1 ? 'active' : ''}`}
                        style={{ display: 'none' }}
                    />
                    <img
                        src={currentImageIndex === 2 ? paper1 : currentImageIndex === 0 ? paper2 : paper3}
                        className={`paper3 ${currentImageIndex === 2 ? 'active' : ''}`}
                        style={{ display: 'none' }}
                    />
                </>
            )}
        </div>
    );
};

export default Home;
